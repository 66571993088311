import React from "react";
import { Link } from "gatsby";
import { Container, Themed } from "theme-ui";
import Layout from "../components/layout";
import im404 from "../img/404.svg";

const Error404 = () => {
  return (
    <Layout seo={{ title: "Fehler 404", noindex: true }}>
      <Container>
        <img src={im404} alt="404 Bild" />
        <Themed.h1>Seite nicht gefunden!</Themed.h1>
        <Themed.p>
          Da hat sich scheinbar der Fehlerteufel eingeschlichen. Zurück zur{" "}
          <Link to="/">Homepage</Link>.
        </Themed.p>
      </Container>
    </Layout>
  );
};

export default Error404;
